<template>
  <div id="Setting">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Setting',
  };
</script>

<style lang="scss" scoped>
  #Setting {
  }
</style>
